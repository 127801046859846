import { combineReducers } from "redux"
import CategoryManagmentRootLevel from "./CategoryManagmentRootReduser"
import CategoryManagmentSubRootLevelreduser from "./CategoryManagmentSubRootLevelreduser"
import CategoryQuestionsReduser from "./CategoryQuestionsReduser"
import CategoryUpdateReduser from "./CategoryUpdateReduser"


export default combineReducers({
    CategoryManagmentRootLevel,
    CategoryManagmentSubRootLevelreduser,
    CategoryUpdateReduser,
    CategoryQuestionsReduser

})