const CategoryManagmentRootLevel = (state = [], action) => {
    /* eslint-disable */
    switch (action.type) {
        case 'GET_ROOT_LEVEL_CATEGORY_SUCCESS':
             if (action.payload.res.data === null) {
                 return state 
                } else { 
                     return  action.payload.res.data
                 }
        case 'DELETE_ROOT_LEVEL_CATEGORY_SUCCESS':
            return state.filter((data) => data.id !== action.payload.id)
        case 'ADD_ROOT_LEVEL_CATEGORY_SUCCESS':
            return state.concat(action.payload.res.data)
        case 'UPDATE_ROOT_LEVEL_CATEGORY_SUCCESS':
            return state.map((d) => {if (action?.payload?.id === d.id){ 
                return {...d, name:action?.payload?.name}
            } else {
                return d
            }
            })
        default:
            return state
    }
}
export default CategoryManagmentRootLevel