import { combineReducers } from "redux"
import validateUserReduser from './ValidateUser'
import validatePassword from './ValidatePassword'
import GetNotificationData from './GetNotificationReduser'
import SideMenuReduser from './SideMenuReduser'
import ValidUserCheckFAIL from './ValidUserCheck'


export default combineReducers({
    validateUserReduser,
    validatePassword,
    GetNotificationData,
    SideMenuReduser,
    ValidUserCheckFAIL
  })