const MuftiBucketCount = (state = 0, action) => {
    switch (action.type) {
        case 'MuftiBucket_SUCCESS':
            if (action.payload.res.data) {
                return action.payload.res.data.length
            }
        default:
            return state
    }
}
export default MuftiBucketCount