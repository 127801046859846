
const UserDetailupdation = (state = [], action) => {
    switch (action.type) {
        case 'ADMIN_CREATE_USER_SUCCESS':
            return action.payload.res.data
        case 'ADMIN_UPDATE_USER_SUCCESS':
            return action.payload.res.data
        default:
            return state
    }
}
export default UserDetailupdation