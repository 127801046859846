const MailDetails = (state = [], action) => {
    switch (action.type) {
        case 'mailDetails':
            return action.payload
        case 'Switch_Ifta_Api_SUCCESS':
            return {
                ...state,
                Metadata: {
                    Name: action?.payload?.res?.data?.UserName, UserID: action?.payload?.res?.data?.UserID
                }
            }
        default:
            return state
    }
}
export default MailDetails