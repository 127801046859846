const AskMuftiAnswer = (state = [], action) => {
    switch (action.type) {
        case 'AskMuftiAnswer_SUCCESS':
            return action.payload.res.data?.answers
        case 'AskMuftiAnswer1_SUCCESS':
            return action.payload.res.data
        default:
            return state
    }
}
export default AskMuftiAnswer