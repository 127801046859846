const GetNotificationReduser = (state = [], action) => {
    switch (action.type) {
        case 'GET_NOTIFICATION_SUCCESS':
            if (action.payload.res.data !== null) {
            return action.payload.res.data
            }
        case 'DELETE_SINGLE_NOTI_SUCCESS':
            return state.filter((data) => data.ID !== action.payload.Notifications.ID)
            // const id = action.payload.Notifications.ID
            // return [...state.filter(c => c.ID !== id)]
        case 'DELETE_ALL_NOTI_SUCCESS':
            return []
        default:
            return state
    }
}
export default GetNotificationReduser