import { combineReducers } from "redux"
import MyQuestion from "./myQuestionReduser"
import AllQuestion from "./AllQuestion"
import AddAQuestion from "./AddAQuestion"
import UserProfileget from './UserProfileget'

export default combineReducers({
    MyQuestion,
    AllQuestion,
    AddAQuestion,
    UserProfileget
  })