const LanguageReduser = (state = [], action) => {
    /* eslint-disable */
    switch (action.type) {
        case 'GET_LANGUAGE_SUCCESS':
            return action.payload.res.data
        case 'GET_ROLES_SUCCESS':
            return action.payload.res.data
        default:
            return state
    }
}
export default LanguageReduser