import { combineReducers } from "redux"
import LogHistory from "../admin/Loghistory"
import LoginHistory from "../admin/LoginHiatoryA1"
import AuditLogin from "../admin/AuditLogin"
import Liveusers from "../admin/LiveUsers"
import AdminUserList from "../admin/endUserManagement/AdminUserList"
import MuftiUserList from "../admin/muftiManagement/MuftiUserList"
import UserDetails from "../admin/muftiManagement/UserDetails"
import UserDetailupdation from "../admin/muftiManagement/UserDetailupdation"
import Category from "../admin/CategoryManagment"
import AllCategoryListReduser from "./AllCategoryList"
import DraftQuestionListReduser from "./DraftQuestionList"
import ApproverInsightsReduser from "./ApproverInsightsReduser"
import ModeratorInsightsReduser from "./ModeratorInsightsReduser"
import MuftiInsightsReduser from "./muftiInsightsReduser"
import GenralStatsReduser from "./GenralStatsReduser"
import LanguageReduser from "./LanguageReduser"

export default combineReducers({
  LogHistory,
  LoginHistory,
  AuditLogin,
  Liveusers,
  AdminUserList,
  MuftiUserList,
  UserDetails,
  UserDetailupdation,
  Category,
  AllCategoryListReduser,
  DraftQuestionListReduser,
  ApproverInsightsReduser,
  ModeratorInsightsReduser,
  MuftiInsightsReduser,
  GenralStatsReduser,
  LanguageReduser
})