const CategoryUpdateReduser = (state = [], action) => {
    switch (action.type) {
        case 'ADD_ROOT_LEVEL_CATEGORY_SUCCESS':
            return action?.payload?.res?.data
        case 'DELETE_ROOT_LEVEL_CATEGORY_SUCCESS':
            return action?.payload?.res?.data
        case 'UPDATE_ROOT_LEVEL_CATEGORY_SUCCESS':
            return action?.payload?.res?.data
        default:
            return state
    }
}
export default CategoryUpdateReduser