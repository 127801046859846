const DraftQuestionListReduser = (state = "", action) => {
    switch (action.type) {
        case 'DRAFT_QUESTION_LIST_SUCCESS':
            return action.payload.res.data
        case 'REVOKE_FROM_DRAFT_SUCCESS':
        return state.filter((data) => data.ID !== action.payload.data?.questionID)
        default:
            return state
    }
}
export default DraftQuestionListReduser