const QuestionTranslate = (state = "", action) => {
    switch (action.type) {
        case 'QUESTION_TRANSLATE_SUCCESS':
            return action.payload.res?.data
        case 'QUESTION_TRANSLATE_RESET':
            return ""
        default:
            return state
    }
}
export default QuestionTranslate