const MyQuestionReduser = (state = [], action) => {
    switch (action.type) {
        case 'USER_ALL_QUESTION_SUCCESS':
            return action?.payload?.res?.data?.list
        case 'USER_ALL_QUESTION_Concat_SUCCESS':
            return state.concat(action?.payload?.res?.data?.list)
        case 'QUESTION_ANSWER_DELETE_SUCCESS':
            return state.filter((data) => data.ID !== action.payload.data?.Qid)
        case 'CLEAR_QUESTION_REDUSER':
            return []
        default:
            return state
    }
}
export default MyQuestionReduser