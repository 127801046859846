const QuestionData = (state = [], action) => {
    switch (action.type) {
        case 'GET_ATEAM_QA_SUCCESS':
            return action?.payload?.res?.data?.list
        case 'GET_ATEAM_APPROVELIST_SUCCESS':
            return action?.payload?.res?.data?.list
        case 'GET_ATEAM_REJECTEDLIST_SUCCESS':
            return action?.payload?.res?.data?.list
        case 'GET_ATEAM_RESET':
            return []
        case 'ATEAM_QUESTION_Approve_SUCCESS':
            const DataID = action.payload?.Question?.ID
            return state.filter((c) => c.ID !== DataID)
        case 'ATEAM_QUESTION_REJECT_SUCCESS':
            const DataRejectID = action.payload?.Question?.ID
            return state.filter((c) => c.ID !== DataRejectID)
        default:
            return state
    }
}
export default QuestionData