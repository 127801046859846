const MyQuestionReduser = (state = "", action) => {
    switch (action.type) {
        case 'MY_QUESTIONS_GET_SUCCESS':
            return action.payload.res.data
        // case 'USER_ASK_QUESTION_SUCCESS':
            // if (state === null) {
            //     return  state
            // } else {
            //     return  state.concat(action.payload.res.data.Question) 
            // }
        default:
            return state
    }
}
export default MyQuestionReduser