const GetDraft = (state = [], action) => {
    switch (action.type) {
        case 'Fcs_Bucket_SUCCESS':
            return action.payload.res.data?.data
        case 'Switch_Ifta_Api_SUCCESS':
            return state.map((d) => {
                if (action?.payload?.QuestionID === d?.ID) {
                    return { ...d,
                        Metadata: {
                            Name: action?.payload?.res?.data?.UserName, UserID: action?.payload?.res?.data?.UserID
                        }
                    }
                } else {
                    return d
                }
            })
        case 'Fcs_Bucket_Concat_SUCCESS':
            return state.concat(action.payload.res.data?.data)
        case 'MuftiInbox_SUCCESS':
            return action.payload.res.data?.list
        case 'MuftiDraft_SUCCESS':
            return action.payload.res.data?.list
        case 'MuftiSentItem_SUCCESS':
            return action.payload.res.data?.list
        case 'MuftiRejected-list_SUCCESS':
            return action.payload.res.data?.list
        case 'MuftiApproved_list_SUCCESS':
            return action.payload.res.data?.list
        case 'EmptyMuftiWorkingArray':
            return []
        case 'MuftiQuestionSelect_SUCCESS':
            const DataID = action.payload?.Question?.ID
            return state.filter((c) => c.ID !== DataID)
        case 'FCS_ADMIN_PUBLISH_QUESTION_SUCCESS':
            const ID = action.payload?.questionId
            // return state.map((d) => {
            //     if (ID === d?.ID) {
            //         return { ...d, Published: action?.payload?.publish }
            //     } else {
            //         return d
            //     }
            // })
            return state.filter((c) => c.ID !== ID)
        case 'Assign_Mufti_SUCCESS':
            const FilterID = action.payload?.QuestionID
            return state.filter((c) => c.ID !== FilterID)
        case 'MuftiSavetoDraft_SUCCESS':
            const DataSaveID = action.payload?.Question?.ID
            // const saveAndSubmitFlag = action.payload?.saveAndSubmitFlag
            const sideName = action.payload?.sideName
            if (sideName !== 'draft') {
                return state.filter((c) => c.ID !== DataSaveID)
            } else return state
        case 'MuftiSubmitToApproval_SUCCESS':
            const DataApprovalID = action.payload?.Question?.ID
            return state.filter((c) => c.ID !== DataApprovalID)
            return state
        case 'MuftiSavetoDraft2_SUCCESS':
            const DataID2 = action.payload?.Question?.ID
            return state.filter((c) => c.ID !== DataID2)
        default:
            return state
    }
}
export default GetDraft