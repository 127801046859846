const SideMenuReduser = (state = [], action) => {
    switch (action.type) {
        case 'SIDE_MENU_SUCCESS':
            return action.payload.res.data
        case 'CLEAR_SIDE_MENU_SUCCESS':
            return []
        default:
            return state
    }
}
export default SideMenuReduser