// ** Redux, Thunk & Root Reducer Imports
import thunk from 'redux-thunk'
import createDebounce from 'redux-debounced'
import rootReducer from '../reducers/rootReducer'
import logger from "redux-logger"
import { createStore, applyMiddleware, compose } from 'redux'
import jwtAction from './JwtIndex'

// ** init middleware
const middleware = [thunk, logger, createDebounce()]
// const middleware = [thunk, createDebounce()]

// ** Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// ** Create store
// const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(jwtAction, ...middleware)))
const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(jwtAction, ...middleware)))

export { store }
