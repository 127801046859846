const validatePassword = (state = '', action) => {
    switch (action.type) {
        case 'VAILDATE_OTP':
            return action.payload.res.data
        case 'VAILDATE_PASSWORD':
            return action.payload.res.data
        case 'VAILDATE_PASSWORD_Failed':
            return action
        case 'LOGOUT_SUCCESS':
            return null
        default:
            return state
    }
}
export default validatePassword