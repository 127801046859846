const validateUserReduser = (state = '', action) => {
    switch (action.type) {
        case 'VAILDATE_NUM':
            return action.payload.res.data
        case 'VAILDATE_EMAIL':
            return action.payload.res.data
        case 'VAILDATE_EMAIL_FAILED':
            return action
        case 'LOGOUT_SUCCESS':
            return ''
        default:
            return state
    }
}
export default validateUserReduser
